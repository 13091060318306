import Earl from "../../assets/Earl.png";
import Cadet from "../../assets/Cadet.png";
import Vizier from "../../assets/Vizier.png";
import Mage from "../../assets/Mage.png";
import RoyalExecutor from "../../assets/RoyalExecutor.png";

export const fiatCurrencies = [
  { name: "Afghanistan", icon: "AF", currency: "AFN" },
  { name: "Albania", icon: "AL", currency: "ALL" },
  { name: "Algeria", icon: "DZ", currency: "DZD" },
  // { "name": "American Samoa", "icon": "AS", "currency": "USD"},
  { name: "Andorra", icon: "AD", currency: "EUR" },
  { name: "Angola", icon: "AO", currency: "AOA" },
  { name: "Anguilla", icon: "AI", currency: "XCD" },
  { name: "Antarctica", icon: "AQ", currency: "" },
  { name: "Antigua and Barbuda", icon: "AG", currency: "XCD" },
  { name: "Argentina", icon: "AR", currency: "ARS" },
  { name: "Armenia", icon: "AM", currency: "AMD" },
  { name: "Aruba", icon: "AW", currency: "AWG" },
  { name: "Australia", icon: "AU", currency: "AUD" },
  { name: "Austria", icon: "AT", currency: "EUR" },
  { name: "Azerbaijan", icon: "AZ", currency: "AZN" },
  { name: "Bahamas", icon: "BS", currency: "BSD" },
  { name: "Bahrain", icon: "BH", currency: "BHD" },
  { name: "Bangladesh", icon: "BD", currency: "BDT" },
  { name: "Barbados", icon: "BB", currency: "BBD" },
  { name: "Belarus", icon: "BY", currency: "BYR" },
  { name: "Belgium", icon: "BE", currency: "EUR" },
  { name: "Belize", icon: "BZ", currency: "BZD" },
  { name: "Benin", icon: "BJ", currency: "XOF" },
  { name: "Bermuda", icon: "BM", currency: "BMD" },
  { name: "Bhutan", icon: "BT", currency: "BTN" },
  { name: "Bolivia", icon: "BO", currency: "BOB" },
  { name: "Bosnia and Herzegovina", icon: "BA", currency: "BAM" },
  { name: "Botswana", icon: "BW", currency: "BWP" },
  { name: "Bouvet Island", icon: "BV", currency: "NOK" },
  { name: "Brazil", icon: "BR", currency: "BRL" },
  // { "name": "British Indian Ocean Territory", "icon": "IO", "currency": "USD"},
  // { "name": "British Virgin Islands", "icon": "VG", "currency": "USD"},
  { name: "Brunei", icon: "BN", currency: "BND" },
  { name: "Bulgaria", icon: "BG", currency: "BGN" },
  { name: "Burkina Faso", icon: "BF", currency: "XOF" },
  { name: "Burundi", icon: "BI", currency: "BIF" },
  { name: "Cambodia", icon: "KH", currency: "KHR" },
  { name: "Cameroon", icon: "CM", currency: "XAF" },
  { name: "Canada", icon: "CA", currency: "CAD" },
  { name: "Cape Verde", icon: "CV", currency: "CVE" },
  { name: "Cayman Islands", icon: "KY", currency: "KYD" },
  { name: "Central African Republic", icon: "CF", currency: "XAF" },
  { name: "Chad", icon: "TD", currency: "XAF" },
  { name: "Chile", icon: "CL", currency: "CLP" },
  { name: "China", icon: "CN", currency: "CNY" },
  { name: "Christmas Island", icon: "CX", currency: "AUD" },
  { name: "Cocos Islands", icon: "CC", currency: "AUD" },
  { name: "Colombia", icon: "CO", currency: "COP" },
  { name: "Comoros", icon: "KM", currency: "KMF" },
  { name: "Cook Islands", icon: "CK", currency: "NZD" },
  { name: "Costa Rica", icon: "CR", currency: "CRC" },
  { name: "Croatia", icon: "HR", currency: "HRK" },
  { name: "Cuba", icon: "CU", currency: "CUP" },
  { name: "Cyprus", icon: "CY", currency: "CYP" },
  { name: "Czech Republic", icon: "CZ", currency: "CZK" },
  { name: "Democratic Republic of the Congo", icon: "CD", currency: "CDF" },
  { name: "Denmark", icon: "DK", currency: "DKK" },
  { name: "Djibouti", icon: "DJ", currency: "DJF" },
  { name: "Dominica", icon: "DM", currency: "XCD" },
  { name: "Dominican Republic", icon: "DO", currency: "DOP" },
  // { "name": "East Timor", "icon": "TL", "currency": "USD"},
  // { "name": "Ecuador", "icon": "EC", "currency": "USD"},
  { name: "Egypt", icon: "EG", currency: "EGP" },
  { name: "El Salvador", icon: "SV", currency: "SVC" },
  { name: "Equatorial Guinea", icon: "GQ", currency: "XAF" },
  { name: "Eritrea", icon: "ER", currency: "ERN" },
  { name: "Estonia", icon: "EE", currency: "EEK" },
  { name: "Ethiopia", icon: "ET", currency: "ETB" },
  { name: "Falkland Islands", icon: "FK", currency: "FKP" },
  { name: "Faroe Islands", icon: "FO", currency: "DKK" },
  { name: "Fiji", icon: "FJ", currency: "FJD" },
  { name: "Finland", icon: "FI", currency: "EUR" },
  { name: "France", icon: "FR", currency: "EUR" },
  { name: "French Guiana", icon: "GF", currency: "EUR" },
  { name: "French Polynesia", icon: "PF", currency: "XPF" },
  { name: "French Southern Territories", icon: "TF", currency: "EUR" },
  { name: "Gabon", icon: "GA", currency: "XAF" },
  { name: "Gambia", icon: "GM", currency: "GMD" },
  { name: "Georgia", icon: "GE", currency: "GEL" },
  { name: "Germany", icon: "DE", currency: "EUR" },
  { name: "Ghana", icon: "GH", currency: "GHC" },
  { name: "Gibraltar", icon: "GI", currency: "GIP" },
  { name: "Greece", icon: "GR", currency: "EUR" },
  { name: "Greenland", icon: "GL", currency: "DKK" },
  { name: "Grenada", icon: "GD", currency: "XCD" },
  { name: "Guadeloupe", icon: "GP", currency: "EUR" },
  // { "name": "Guam", "icon": "GU", "currency": "USD"},
  { name: "Guatemala", icon: "GT", currency: "GTQ" },
  { name: "Guinea", icon: "GN", currency: "GNF" },
  { name: "Guinea-Bissau", icon: "GW", currency: "XOF" },
  { name: "Guyana", icon: "GY", currency: "GYD" },
  { name: "Haiti", icon: "HT", currency: "HTG" },
  { name: "Heard Island and McDonald Islands", icon: "HM", currency: "AUD" },
  { name: "Honduras", icon: "HN", currency: "HNL" },
  { name: "Hong Kong", icon: "HK", currency: "HKD" },
  { name: "Hungary", icon: "HU", currency: "HUF" },
  { name: "Iceland", icon: "IS", currency: "ISK" },
  { name: "India", icon: "IN", currency: "INR" },
  { name: "Indonesia", icon: "ID", currency: "IDR" },
  { name: "Iran", icon: "IR", currency: "IRR" },
  { name: "Iraq", icon: "IQ", currency: "IQD" },
  { name: "Ireland", icon: "IE", currency: "EUR" },
  { name: "Israel", icon: "IL", currency: "ILS" },
  { name: "Italy", icon: "IT", currency: "EUR" },
  { name: "Ivory Coast", icon: "CI", currency: "XOF" },
  { name: "Jamaica", icon: "JM", currency: "JMD" },
  { name: "Japan", icon: "JP", currency: "JPY" },
  { name: "Jordan", icon: "JO", currency: "JOD" },
  { name: "Kazakhstan", icon: "KZ", currency: "KZT" },
  { name: "Kenya", icon: "KE", currency: "KES" },
  { name: "Kiribati", icon: "KI", currency: "AUD" },
  { name: "Kuwait", icon: "KW", currency: "KWD" },
  { name: "Kyrgyzstan", icon: "KG", currency: "KGS" },
  { name: "Laos", icon: "LA", currency: "LAK" },
  { name: "Latvia", icon: "LV", currency: "LVL" },
  { name: "Lebanon", icon: "LB", currency: "LBP" },
  { name: "Lesotho", icon: "LS", currency: "LSL" },
  { name: "Liberia", icon: "LR", currency: "LRD" },
  { name: "Libya", icon: "LY", currency: "LYD" },
  { name: "Liechtenstein", icon: "LI", currency: "CHF" },
  { name: "Lithuania", icon: "LT", currency: "LTL" },
  { name: "Luxembourg", icon: "LU", currency: "EUR" },
  { name: "Macao", icon: "MO", currency: "MOP" },
  { name: "Macedonia", icon: "MK", currency: "MKD" },
  { name: "Madagascar", icon: "MG", currency: "MGA" },
  { name: "Malawi", icon: "MW", currency: "MWK" },
  { name: "Malaysia", icon: "MY", currency: "MYR" },
  { name: "Maldives", icon: "MV", currency: "MVR" },
  { name: "Mali", icon: "ML", currency: "XOF" },
  { name: "Malta", icon: "MT", currency: "MTL" },
  // { "name": "Marshall Islands", "icon": "MH", "currency": "USD"},
  { name: "Martinique", icon: "MQ", currency: "EUR" },
  { name: "Mauritania", icon: "MR", currency: "MRO" },
  { name: "Mauritius", icon: "MU", currency: "MUR" },
  { name: "Mayotte", icon: "YT", currency: "EUR" },
  { name: "Mexico", icon: "MX", currency: "MXN" },
  // { "name": "Micronesia", "icon": "FM", "currency": "USD"},
  { name: "Moldova", icon: "MD", currency: "MDL" },
  { name: "Monaco", icon: "MC", currency: "EUR" },
  { name: "Mongolia", icon: "MN", currency: "MNT" },
  { name: "Montserrat", icon: "MS", currency: "XCD" },
  { name: "Morocco", icon: "MA", currency: "MAD" },
  { name: "Mozambique", icon: "MZ", currency: "MZN" },
  { name: "Myanmar", icon: "MM", currency: "MMK" },
  { name: "Namibia", icon: "NA", currency: "NAD" },
  { name: "Nauru", icon: "NR", currency: "AUD" },
  { name: "Nepal", icon: "NP", currency: "NPR" },
  { name: "Netherlands", icon: "NL", currency: "EUR" },
  { name: "Netherlands Antilles", icon: "AN", currency: "ANG" },
  { name: "New Caledonia", icon: "NC", currency: "XPF" },
  { name: "New Zealand", icon: "NZ", currency: "NZD" },
  { name: "Nicaragua", icon: "NI", currency: "NIO" },
  { name: "Niger", icon: "NE", currency: "XOF" },
  { name: "Nigeria", icon: "NG", currency: "NGN" },
  { name: "Niue", icon: "NU", currency: "NZD" },
  { name: "Norfolk Island", icon: "NF", currency: "AUD" },
  { name: "North Korea", icon: "KP", currency: "KPW" },
  // { "name": "Northern Mariana Islands", "icon": "MP", "currency": "USD"},
  { name: "Norway", icon: "NO", currency: "NOK" },
  { name: "Oman", icon: "OM", currency: "OMR" },
  { name: "Pakistan", icon: "PK", currency: "PKR" },
  // { "name": "Palau", "icon": "PW", "currency": "USD"},
  { name: "Palestinian Territory", icon: "PS", currency: "ILS" },
  { name: "Panama", icon: "PA", currency: "PAB" },
  { name: "Papua New Guinea", icon: "PG", currency: "PGK" },
  { name: "Paraguay", icon: "PY", currency: "PYG" },
  { name: "Peru", icon: "PE", currency: "PEN" },
  { name: "Philippines", icon: "PH", currency: "PHP" },
  { name: "Pitcairn", icon: "PN", currency: "NZD" },
  { name: "Poland", icon: "PL", currency: "PLN" },
  { name: "Portugal", icon: "PT", currency: "EUR" },
  { name: "Puerto Rico", icon: "PR", currency: "USD" },
  { name: "Qatar", icon: "QA", currency: "QAR" },
  { name: "Republic of the Congo", icon: "CG", currency: "XAF" },
  { name: "Reunion", icon: "RE", currency: "EUR" },
  { name: "Romania", icon: "RO", currency: "RON" },
  { name: "Russia", icon: "RU", currency: "RUB" },
  { name: "Rwanda", icon: "RW", currency: "RWF" },
  { name: "Saint Helena", icon: "SH", currency: "SHP" },
  { name: "Saint Kitts and Nevis", icon: "KN", currency: "XCD" },
  { name: "Saint Lucia", icon: "LC", currency: "XCD" },
  { name: "Saint Pierre and Miquelon", icon: "PM", currency: "EUR" },
  { name: "Saint Vincent and the Grenadines", icon: "VC", currency: "XCD" },
  { name: "Samoa", icon: "WS", currency: "WST" },
  { name: "San Marino", icon: "SM", currency: "EUR" },
  { name: "Sao Tome and Principe", icon: "ST", currency: "STD" },
  { name: "Saudi Arabia", icon: "SA", currency: "SAR" },
  { name: "Senegal", icon: "SN", currency: "XOF" },
  { name: "Serbia and Montenegro", icon: "CS", currency: "RSD" },
  { name: "Seychelles", icon: "SC", currency: "SCR" },
  { name: "Sierra Leone", icon: "SL", currency: "SLL" },
  { name: "Singapore", icon: "SG", currency: "SGD" },
  { name: "Slovakia", icon: "SK", currency: "SKK" },
  { name: "Slovenia", icon: "SI", currency: "EUR" },
  { name: "Solomon Islands", icon: "SB", currency: "SBD" },
  { name: "Somalia", icon: "SO", currency: "SOS" },
  { name: "South Africa", icon: "ZA", currency: "ZAR" },
  {
    name: "South Georgia and the South Sandwich Islands",
    icon: "GS",
    currency: "GBP",
  },
  { name: "South Korea", icon: "KR", currency: "KRW" },
  { name: "Spain", icon: "ES", currency: "EUR" },
  { name: "Sri Lanka", icon: "LK", currency: "LKR" },
  { name: "Sudan", icon: "SD", currency: "SDD" },
  { name: "Suriname", icon: "SR", currency: "SRD" },
  { name: "Svalbard and Jan Mayen", icon: "SJ", currency: "NOK" },
  { name: "Swaziland", icon: "SZ", currency: "SZL" },
  { name: "Sweden", icon: "SE", currency: "SEK" },
  { name: "Switzerland", icon: "CH", currency: "CHF" },
  { name: "Syria", icon: "SY", currency: "SYP" },
  { name: "Taiwan", icon: "TW", currency: "TWD" },
  { name: "Tajikistan", icon: "TJ", currency: "TJS" },
  { name: "Tanzania", icon: "TZ", currency: "TZS" },
  { name: "Thailand", icon: "TH", currency: "THB" },
  { name: "Togo", icon: "TG", currency: "XOF" },
  { name: "Tokelau", icon: "TK", currency: "NZD" },
  { name: "Tonga", icon: "TO", currency: "TOP" },
  { name: "Trinidad and Tobago", icon: "TT", currency: "TTD" },
  { name: "Tunisia", icon: "TN", currency: "TND" },
  { name: "Turkey", icon: "TR", currency: "TRY" },
  { name: "Turkmenistan", icon: "TM", currency: "TMM" },
  // {"name": "Turks and Caicos Islands","icon": "TC","currency": "USD"},
  { name: "Tuvalu", icon: "TV", currency: "AUD" },
  // {"name": "U.S. Virgin Islands","icon": "VI","currency": "USD"},
  { name: "Uganda", icon: "UG", currency: "UGX" },
  { name: "Ukraine", icon: "UA", currency: "UAH" },
  { name: "United Arab Emirates", icon: "AE", currency: "AED" },
  { name: "United Kingdom", icon: "GB", currency: "GBP" },
  { name: "United States", icon: "US", currency: "USD" },
  // {"name": "United States Minor Outlying Islands","icon": "UM","currency": "USD"},
  { name: "Uruguay", icon: "UY", currency: "UYU" },
  { name: "Uzbekistan", icon: "UZ", currency: "UZS" },
  { name: "Vanuatu", icon: "VU", currency: "VUV" },
  { name: "Vatican", icon: "VA", currency: "EUR" },
  { name: "Venezuela", icon: "VE", currency: "VEF" },
  { name: "Vietnam", icon: "VN", currency: "VND" },
  { name: "Wallis and Futuna", icon: "WF", currency: "XPF" },
  { name: "Western Sahara", icon: "EH", currency: "MAD" },
  { name: "Yemen", icon: "YE", currency: "YER" },
  { name: "Zambia", icon: "ZM", currency: "ZMK" },
  { name: "Zimbabwe", icon: "ZW", currency: "ZWD" },
];

export const STAKINGCONTRACTADDRESS =
  "0x3f15E60f6BB55E382cd7dde6bd74499495424bA5";

export const RANKSBYTES = [
  {
    name: "Royal Executor",
    bytes: "0x526f79616c204578656375746f72000000000000000000000000000000000000",
  },
  {
    name: "Vizier",
    bytes: "0x56495a4945520000000000000000000000000000000000000000000000000000",
  },
  {
    name: "Earl",
    bytes: "0x4561726c00000000000000000000000000000000000000000000000000000000",
  },
  {
    name: "Mage",
    bytes: "0x4d61676500000000000000000000000000000000000000000000000000000000",
  },
  {
    name: "Cadet",
    bytes: "0x4361646574000000000000000000000000000000000000000000000000000000",
  },
];

export const rankInfo = [
  {
    title: "Royal Executor",
    range: "Can vote on disputes $0 - $10,000",
    text: "You need to stake an NFT of $4,000 or stake $4,000 in $RGP",
    img: RoyalExecutor,
  },
  {
    title: "Vizier",
    range: "Can vote on disputes $0 - $4,000",
    text: "You need to stake an NFT of $10,000 or stake $10,000 in $RGP",
    img: Vizier,
  },
  {
    title: "Earl",
    range: "Can vote on disputes $0 - $2,500",
    text: "You need to stake an NFT of $2,500 or stake $2,500 in $RGP",
    img: Earl,
  },
  {
    title: "Mage",
    range: "Can vote on disputes $0 - $1,500",
    text: "You need to stake an NFT of $1,500 or stake $1,500 in $RGP",
    img: Mage,
  },
  {
    title: "Cadet",
    range: "Can vote on disputes $0 - $500",
    text: "You need to stake an NFT of $2,500 or stake $2,500 in $RGP",
    img: Cadet,
  },
];

// export const APIENDPOINT: { [key: string]: string } = {
//   "1": "",
//   "3": "",
//   "56": "api.bscscan.com/api",
//   "97": "api-testnet.bscscan.com/api",
//   "137": "api.polygonscan.com/api",
//   "80001": "api-testnet.polygonscan.com/api",
//   "42261": "testnet.explorer.emerald.oasis.dev/api",
//   "42262": "explorer.emerald.oasis.dev/api",
// };

// export const APIKEY: { [key: string]: string } = {
//   "1": "",
//   "3": "",
//   "56": "AATZWFQ47VX3Y1DN7M97BJ5FEJR6MGRQSD",
//   "97": "9ZWY7UIMN42RE3T4DD87FQ7DYA7ZZJDW91",
//   "137": "89B4F6NVVEVGC8EMDCJVRJMVGSCVHHZTR7",
//   "80001": "89B4F6NVVEVGC8EMDCJVRJMVGSCVHHZTR7",
//   "42261": "",
//   "42262": "",
// };

export const APIENDPOINT: { [key: string]: string } = {
  "1": "",
  "3": "",
  "56": "api.bscscan.com/api",
  "97": "api-testnet.bscscan.com/api",
  "137": "api.polygonscan.com/api",
  "80001": "api-testnet.polygonscan.com/api",
  "42261": "testnet.explorer.emerald.oasis.dev/api",
  "42262": "explorer.emerald.oasis.dev/api",
};

export const APIKEY: { [key: string]: string } = {
  "1": "",
  "3": "",
  "56": "AATZWFQ47VX3Y1DN7M97BJ5FEJR6MGRQSD",
  "97": "AATZWFQ47VX3Y1DN7M97BJ5FEJR6MGRQSD",
  "137": "89B4F6NVVEVGC8EMDCJVRJMVGSCVHHZTR7",
  "80001": "89B4F6NVVEVGC8EMDCJVRJMVGSCVHHZTR7",
  "42261": "",
  "42262": "",
};

export const SUPPORTEDCOUNTRIES = [
  { name: "Afghanistan", countryCode: "93", icon: "AF" },
  { name: "Albania", countryCode: "355", icon: "AL" },
  { name: "Algeria", countryCode: "213", icon: "DZ" },
  { name: "American Samoa", countryCode: "1-684", icon: "AS" },
  { name: "Andorra", countryCode: "376", icon: "AD" },
  { name: "Angola", countryCode: "244", icon: "AO" },
  { name: "Anguilla", countryCode: "1-264", icon: "AI" },
  { name: "Antarctica", countryCode: "672", icon: "AQ" },
  { name: "Antigua and Barbuda", countryCode: "1-268", icon: "AG" },
  { name: "Argentina", countryCode: "54", icon: "AR" },
  { name: "Armenia", countryCode: "374", icon: "AM" },
  { name: "Aruba", countryCode: "297", icon: "AW" },
  { name: "Australia", countryCode: "61", icon: "AU" },
  { name: "Austria", countryCode: "43", icon: "AT" },
  { name: "Azerbaijan", countryCode: "994", icon: "AZ" },
  { name: "Bahamas", countryCode: "1-242", icon: "BS" },
  { name: "Bahrain", countryCode: "973", icon: "BH" },
  { name: "Bangladesh", countryCode: "880", icon: "BD" },
  { name: "Barbados", countryCode: "1-246", icon: "BB" },
  { name: "Belarus", countryCode: "375", icon: "BY" },
  { name: "Belgium", countryCode: "32", icon: "BE" },
  { name: "Belize", countryCode: "501", icon: "BZ" },
  { name: "Benin", countryCode: "229", icon: "BJ" },
  { name: "Bermuda", countryCode: "1-441", icon: "BM" },
  { name: "Bhutan", countryCode: "975", icon: "BT" },
  { name: "Bolivia", countryCode: "591", icon: "BO" },
  { name: "Bosnia and Herzegovina", countryCode: "387", icon: "BA" },
  { name: "Botswana", countryCode: "267", icon: "BW" },
  { name: "Brazil", countryCode: "55", icon: "BR" },
  { name: "British Indian Ocean Territory", countryCode: "246", icon: "IO" },
  { name: "British Virgin Islands", countryCode: "1-284", icon: "VG" },
  { name: "Brunei", countryCode: "673", icon: "BN" },
  { name: "Bulgaria", countryCode: "359", icon: "BG" },
  { name: "Burkina Faso", countryCode: "226", icon: "BF" },
  { name: "Burundi", countryCode: "257", icon: "BI" },
  { name: "Cambodia", countryCode: "855", icon: "KH" },
  { name: "Cameroon", countryCode: "237", icon: "CM" },
  { name: "Canada", countryCode: "1", icon: "CA" },
  { name: "Cape Verde", countryCode: "238", icon: "CV" },
  { name: "Cayman Islands", countryCode: "1-345", icon: "KY" },
  { name: "Central African Republic", countryCode: "236", icon: "CF" },
  { name: "Chad", countryCode: "235", icon: "TD" },
  { name: "Chile", countryCode: "56", icon: "CL" },
  { name: "China", countryCode: "86", icon: "CN" },
  { name: "Christmas Island", countryCode: "61", icon: "CX" },
  { name: "Cocos Islands", countryCode: "61", icon: "CC" },
  { name: "Colombia", countryCode: "57", icon: "CO" },
  { name: "Comoros", countryCode: "269", icon: "KM" },
  { name: "Cook Islands", countryCode: "682", icon: "CK" },
  { name: "Costa Rica", countryCode: "506", icon: "CR" },
  { name: "Croatia", countryCode: "385", icon: "HR" },
  { name: "Cuba", countryCode: "53", icon: "CU" },
  { name: "Curacao", countryCode: "599", icon: "CW" },
  { name: "Cyprus", countryCode: "357", icon: "CY" },
  { name: "Czech Republic", countryCode: "420", icon: "CZ" },
  { name: "Democratic Republic of the Congo", countryCode: "243", icon: "CD" },
  { name: "Denmark", countryCode: "45", icon: "DK" },
  { name: "Djibouti", countryCode: "253", icon: "DJ" },
  { name: "Dominica", countryCode: "1-767", icon: "DM" },
  {
    name: "Dominican Republic",
    countryCode: "1-809, 1-829, 1-849",
    icon: "DO",
  },
  { name: "East Timor", countryCode: "670", icon: "TL" },
  { name: "Ecuador", countryCode: "593", icon: "EC" },
  { name: "Egypt", countryCode: "20", icon: "EG" },
  { name: "El Salvador", countryCode: "503", icon: "SV" },
  { name: "Equatorial Guinea", countryCode: "240", icon: "GQ" },
  { name: "Eritrea", countryCode: "291", icon: "ER" },
  { name: "Estonia", countryCode: "372", icon: "EE" },
  { name: "Ethiopia", countryCode: "251", icon: "ET" },
  { name: "Falkland Islands", countryCode: "500", icon: "FK" },
  { name: "Faroe Islands", countryCode: "298", icon: "FO" },
  { name: "Fiji", countryCode: "679", icon: "FJ" },
  { name: "Finland", countryCode: "358", icon: "FI" },
  { name: "France", countryCode: "33", icon: "FR" },
  { name: "French Polynesia", countryCode: "689", icon: "PF" },
  { name: "Gabon", countryCode: "241", icon: "GA" },
  { name: "Gambia", countryCode: "220", icon: "GM" },
  { name: "Georgia", countryCode: "995", icon: "GE" },
  { name: "Germany", countryCode: "49", icon: "DE" },
  { name: "Ghana", countryCode: "233", icon: "GH" },
  { name: "Gibraltar", countryCode: "350", icon: "GI" },
  { name: "Greece", countryCode: "30", icon: "GR" },
  { name: "Greenland", countryCode: "299", icon: "GL" },
  { name: "Grenada", countryCode: "1-473", icon: "GD" },
  { name: "Guam", countryCode: "1-671", icon: "GU" },
  { name: "Guatemala", countryCode: "502", icon: "GT" },
  { name: "Guernsey", countryCode: "44-1481", icon: "GG" },
  { name: "Guinea", countryCode: "224", icon: "GN" },
  { name: "Guinea-Bissau", countryCode: "245", icon: "GW" },
  { name: "Guyana", countryCode: "592", icon: "GY" },
  { name: "Haiti", countryCode: "509", icon: "HT" },
  { name: "Honduras", countryCode: "504", icon: "HN" },
  { name: "Hong Kong", countryCode: "852", icon: "HK" },
  { name: "Hungary", countryCode: "36", icon: "HU" },
  { name: "Iceland", countryCode: "354", icon: "IS" },
  { name: "India", countryCode: "91", icon: "IN" },
  { name: "Indonesia", countryCode: "62", icon: "ID" },
  { name: "Iran", countryCode: "98", icon: "IR" },
  { name: "Iraq", countryCode: "964", icon: "IQ" },
  { name: "Ireland", countryCode: "353", icon: "IE" },
  { name: "Isle of Man", countryCode: "44-1624", icon: "IM" },
  { name: "Israel", countryCode: "972", icon: "IL" },
  { name: "Italy", countryCode: "39", icon: "IT" },
  { name: "Ivory Coast", countryCode: "225", icon: "CI" },
  { name: "Jamaica", countryCode: "1-876", icon: "JM" },
  { name: "Japan", countryCode: "81", icon: "JP" },
  { name: "Jersey", countryCode: "44-1534", icon: "JE" },
  { name: "Jordan", countryCode: "962", icon: "JO" },
  { name: "Kazakhstan", countryCode: "7", icon: "KZ" },
  { name: "Kenya", countryCode: "254", icon: "KE" },
  { name: "Kiribati", countryCode: "686", icon: "KI" },
  { name: "Kosovo", countryCode: "383", icon: "XK" },
  { name: "Kuwait", countryCode: "965", icon: "KW" },
  { name: "Kyrgyzstan", countryCode: "996", icon: "KG" },
  { name: "Laos", countryCode: "856", icon: "LA" },
  { name: "Latvia", countryCode: "371", icon: "LV" },
  { name: "Lebanon", countryCode: "961", icon: "LB" },
  { name: "Lesotho", countryCode: "266", icon: "LS" },
  { name: "Liberia", countryCode: "231", icon: "LR" },
  { name: "Libya", countryCode: "218", icon: "LY" },
  { name: "Liechtenstein", countryCode: "423", icon: "LI" },
  { name: "Lithuania", countryCode: "370", icon: "LT" },
  { name: "Luxembourg", countryCode: "352", icon: "LU" },
  { name: "Macao", countryCode: "853", icon: "MO" },
  { name: "Macedonia", countryCode: "389", icon: "MK" },
  { name: "Madagascar", countryCode: "261", icon: "MG" },
  { name: "Malawi", countryCode: "265", icon: "MW" },
  { name: "Malaysia", countryCode: "60", icon: "MY" },
  { name: "Maldives", countryCode: "960", icon: "MV" },
  { name: "Mali", countryCode: "223", icon: "ML" },
  { name: "Malta", countryCode: "356", icon: "MT" },
  { name: "Marshall Islands", countryCode: "692", icon: "MH" },
  { name: "Mauritania", countryCode: "222", icon: "MR" },
  { name: "Mauritius", countryCode: "230", icon: "MU" },
  { name: "Mayotte", countryCode: "262", icon: "YT" },
  { name: "Mexico", countryCode: "52", icon: "MX" },
  { name: "Micronesia", countryCode: "691", icon: "FM" },
  { name: "Moldova", countryCode: "373", icon: "MD" },
  { name: "Monaco", countryCode: "377", icon: "MC" },
  { name: "Mongolia", countryCode: "976", icon: "MN" },
  { name: "Montenegro", countryCode: "382", icon: "ME" },
  { name: "Montserrat", countryCode: "1-664", icon: "MS" },
  { name: "Morocco", countryCode: "212", icon: "MA" },
  { name: "Mozambique", countryCode: "258", icon: "MZ" },
  { name: "Myanmar", countryCode: "95", icon: "MM" },
  { name: "Namibia", countryCode: "264", icon: "NA" },
  { name: "Nauru", countryCode: "674", icon: "NR" },
  { name: "Nepal", countryCode: "977", icon: "NP" },
  { name: "Netherlands", countryCode: "31", icon: "NL" },
  { name: "Netherlands Antilles", countryCode: "599", icon: "AN" },
  { name: "New Caledonia", countryCode: "687", icon: "NC" },
  { name: "New Zealand", countryCode: "64", icon: "NZ" },
  { name: "Nicaragua", countryCode: "505", icon: "NI" },
  { name: "Niger", countryCode: "227", icon: "NE" },
  { name: "Nigeria", countryCode: "234", icon: "NG" },
  { name: "Niue", countryCode: "683", icon: "NU" },
  { name: "North Korea", countryCode: "850", icon: "KP" },
  { name: "Northern Mariana Islands", countryCode: "1-670", icon: "MP" },
  { name: "Norway", countryCode: "47", icon: "NO" },
  { name: "Oman", countryCode: "968", icon: "OM" },
  { name: "Pakistan", countryCode: "92", icon: "PK" },
  { name: "Palau", countryCode: "680", icon: "PW" },
  { name: "Palestine", countryCode: "970", icon: "PS" },
  { name: "Panama", countryCode: "507", icon: "PA" },
  { name: "Papua New Guinea", countryCode: "675", icon: "PG" },
  { name: "Paraguay", countryCode: "595", icon: "PY" },
  { name: "Peru", countryCode: "51", icon: "PE" },
  { name: "Philippines", countryCode: "63", icon: "PH" },
  { name: "Pitcairn", countryCode: "64", icon: "PN" },
  { name: "Poland", countryCode: "48", icon: "PL" },
  { name: "Portugal", countryCode: "351", icon: "PT" },
  { name: "Puerto Rico", countryCode: "1-787, 1-939", icon: "PR" },
  { name: "Qatar", countryCode: "974", icon: "QA" },
  { name: "Republic of the Congo", countryCode: "242", icon: "CG" },
  { name: "Reunion", countryCode: "262", icon: "RE" },
  { name: "Romania", countryCode: "40", icon: "RO" },
  { name: "Russia", countryCode: "7", icon: "RU" },
  { name: "Rwanda", countryCode: "250", icon: "RW" },
  { name: "Saint Barthelemy", countryCode: "590", icon: "BL" },
  { name: "Saint Helena", countryCode: "290", icon: "SH" },
  { name: "Saint Kitts and Nevis", countryCode: "1-869", icon: "KN" },
  { name: "Saint Lucia", countryCode: "1-758", icon: "LC" },
  { name: "Saint Martin", countryCode: "590", icon: "MF" },
  { name: "Saint Pierre and Miquelon", countryCode: "508", icon: "PM" },
  {
    name: "Saint Vincent and the Grenadines",
    countryCode: "1-784",
    icon: "VC",
  },
  { name: "Samoa", countryCode: "685", icon: "WS" },
  { name: "San Marino", countryCode: "378", icon: "SM" },
  { name: "Sao Tome and Principe", countryCode: "239", icon: "ST" },
  { name: "Saudi Arabia", countryCode: "966", icon: "SA" },
  { name: "Senegal", countryCode: "221", icon: "SN" },
  { name: "Serbia", countryCode: "381", icon: "RS" },
  { name: "Seychelles", countryCode: "248", icon: "SC" },
  { name: "Sierra Leone", countryCode: "232", icon: "SL" },
  { name: "Singapore", countryCode: "65", icon: "SG" },
  { name: "Sint Maarten", countryCode: "1-721", icon: "SX" },
  { name: "Slovakia", countryCode: "421", icon: "SK" },
  { name: "Slovenia", countryCode: "386", icon: "SI" },
  { name: "Solomon Islands", countryCode: "677", icon: "SB" },
  { name: "Somalia", countryCode: "252", icon: "SO" },
  { name: "South Africa", countryCode: "27", icon: "ZA" },
  { name: "South Korea", countryCode: "82", icon: "KR" },
  { name: "South Sudan", countryCode: "211", icon: "SS" },
  { name: "Spain", countryCode: "34", icon: "ES" },
  { name: "Sri Lanka", countryCode: "94", icon: "LK" },
  { name: "Sudan", countryCode: "249", icon: "SD" },
  { name: "Suriname", countryCode: "597", icon: "SR" },
  { name: "Svalbard and Jan Mayen", countryCode: "47", icon: "SJ" },
  { name: "Swaziland", countryCode: "268", icon: "SZ" },
  { name: "Sweden", countryCode: "46", icon: "SE" },
  { name: "Switzerland", countryCode: "41", icon: "CH" },
  { name: "Syria", countryCode: "963", icon: "SY" },
  { name: "Taiwan", countryCode: "886", icon: "TW" },
  { name: "Tajikistan", countryCode: "992", icon: "TJ" },
  { name: "Tanzania", countryCode: "255", icon: "TZ" },
  { name: "Thailand", countryCode: "66", icon: "TH" },
  { name: "Togo", countryCode: "228", icon: "TG" },
  { name: "Tokelau", countryCode: "690", icon: "TK" },
  { name: "Tonga", countryCode: "676", icon: "TO" },
  { name: "Trinidad and Tobago", countryCode: "1-868", icon: "TT" },
  { name: "Tunisia", countryCode: "216", icon: "TN" },
  { name: "Turkey", countryCode: "90", icon: "TR" },
  { name: "Turkmenistan", countryCode: "993", icon: "TM" },
  { name: "Turks and Caicos Islands", countryCode: "1-649", icon: "TC" },
  { name: "Tuvalu", countryCode: "688", icon: "TV" },
  { name: "U.S. Virgin Islands", countryCode: "1-340", icon: "VI" },
  { name: "Uganda", countryCode: "256", icon: "UG" },
  { name: "Ukraine", countryCode: "380", icon: "UA" },
  { name: "United Arab Emirates", countryCode: "971", icon: "AE" },
  { name: "United Kingdom", countryCode: "44", icon: "GB" },
  { name: "United States", countryCode: "1", icon: "US" },
  { name: "Uruguay", countryCode: "598", icon: "UY" },
  { name: "Uzbekistan", countryCode: "998", icon: "UZ" },
  { name: "Vanuatu", countryCode: "678", icon: "VU" },
  { name: "Vatican", countryCode: "379", icon: "VA" },
  { name: "Venezuela", countryCode: "58", icon: "VE" },
  { name: "Vietnam", countryCode: "84", icon: "VN" },
  { name: "Wallis and Futuna", countryCode: "681", icon: "WF" },
  { name: "Western Sahara", countryCode: "212", icon: "EH" },
  { name: "Yemen", countryCode: "967", icon: "YE" },
  { name: "Zambia", countryCode: "260", icon: "ZM" },
  { name: "Zimbabwe", countryCode: "263", icon: "ZW" },
];

export const nft_chains = [97, 56, 137, 8001];
