import { useColorModeValue } from '@chakra-ui/react';
import React from 'react'

const NotificationIcon = ({isActive} :{isActive ?: boolean}) => {
    
  const bg = useColorModeValue( '#15202B' , '#FFFFFF');
  return (
   <>
    <svg
        width="19"
        height="21"
        viewBox="0 0 19 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.01987 18.5299C6.68987 18.5299 4.35987 18.1599 2.14987 17.4199C1.30987 17.1299 0.669869 16.5399 0.389869 15.7699C0.0998691 14.9999 0.199869 14.1499 0.659869 13.3899L1.80987 11.4799C2.04987 11.0799 2.26987 10.2799 2.26987 9.80992V6.91992C2.26987 3.19992 5.29987 0.169922 9.01987 0.169922C12.7399 0.169922 15.7699 3.19992 15.7699 6.91992V9.80992C15.7699 10.2699 15.9899 11.0799 16.2299 11.4899L17.3699 13.3899C17.7999 14.1099 17.8799 14.9799 17.5899 15.7699C17.2999 16.5599 16.6699 17.1599 15.8799 17.4199C13.6799 18.1599 11.3499 18.5299 9.01987 18.5299ZM9.01987 1.66992C6.12987 1.66992 3.76987 4.01992 3.76987 6.91992V9.80992C3.76987 10.5399 3.46987 11.6199 3.09987 12.2499L1.94987 14.1599C1.72987 14.5299 1.66987 14.9199 1.79987 15.2499C1.91987 15.5899 2.21987 15.8499 2.62987 15.9899C6.80987 17.3899 11.2399 17.3899 15.4199 15.9899C15.7799 15.8699 16.0599 15.5999 16.1899 15.2399C16.3199 14.8799 16.2899 14.4899 16.0899 14.1599L14.9399 12.2499C14.5599 11.5999 14.2699 10.5299 14.2699 9.79992V6.91992C14.2699 4.01992 11.9199 1.66992 9.01987 1.66992Z"
          fill={bg}
        />
        <path
          d="M9.02344 20.8101C8.03344 20.8101 7.07344 20.4101 6.37344 19.7101C5.67344 19.0101 5.27344 18.0501 5.27344 17.0601H6.77344C6.77344 17.6501 7.01344 18.2301 7.43344 18.6501C7.85344 19.0701 8.43344 19.3101 9.02344 19.3101C10.2634 19.3101 11.2734 18.3001 11.2734 17.0601H12.7734C12.7734 19.1301 11.0934 20.8101 9.02344 20.8101Z"
          fill={bg}
        />
       { isActive && <circle cx="15" cy="4" r="4" fill="#CC334F" />}
      </svg>
   </>
  )
}

export default NotificationIcon